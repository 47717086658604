import './main.css'
import { Elm } from './Main.elm'
// import * as serviceWorker from './serviceWorker';


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

/* global MusicKit */
/* global localStorage */
document.addEventListener('musickitloaded', function () {
  // MusicKit global is now defined
  MusicKit.configure({
    developerToken: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ijc4SDlBM1M2SjUifQ.eyJpYXQiOjE1Nzc1NTU2NDQsImV4cCI6MTU5MzEwNzY0NCwiaXNzIjoiV1BCTjc2WUtYMiJ9.YROqIsN98baUR-jPe3km98Cg_DHw4-N_h1DW59IY14vP7zu-BJ2PvDegXadrllU4R69ska0n0vPK1o011N2rMA',
    app: {
      name: 'My Cool Web App',
      build: '1978.4.1'
    }
  })

  const music = MusicKit.getInstance()
  const elm = Elm.Main.init({
    flags: { 
      isAuthorized: music.isAuthorized,
      workingPlaylist: JSON.parse(localStorage.getItem("workingPlaylist")),
      restingPlaylist: JSON.parse(localStorage.getItem("restingPlaylist"))
    }
  })

  elm.ports.authorize.subscribe(() => {
    music.authorize().then((token) => {
      elm.ports.isAuthorized.send(true)
    })
  })

  elm.ports.loadRecentlyPlayed.subscribe(() => {
    music.api.recentPlayed().then(resp => {
      console.log(resp)
      let items = resp.map(decodeItem)
      console.log(items)
      elm.ports.recentlyPlayedLoaded.send(items)
    })
  })

  var searchText = ""
  let searchFn = debounce(() => {
    music.api.search(searchText).then((resp) => {
      let items = resp.playlists.data.map(decodeItem)
      console.log(items)
      elm.ports.playlistResults.send(items)
      searchText = ""
    })
  }, 250)

  elm.ports.searchPlaylists.subscribe(text => {
    searchText = text
    searchFn()
  })

  elm.ports.saveWorkingPlaylist.subscribe(playlist => {
    localStorage.setItem("workingPlaylist", JSON.stringify(playlist))
  })

  elm.ports.saveRestingPlaylist.subscribe(playlist => {
    localStorage.setItem("restingPlaylist", JSON.stringify(playlist))
  })

  elm.ports.stopPlaying.subscribe(() => {
    music.player.stop()
    music.player.queue.items.length = 0
  })

  elm.ports.startPlaying.subscribe((id) => {
    music.player.stop()
    music.player.queue.items.length = 0
    music.setQueue({ playlist: id}).then(() => {
      music.player.queue.shuffle()
      console.log(music.player.queue.items)
      music.player.play()
    })
  })

  music.addEventListener(MusicKit.Events.mediaItemDidChange, (state) => {
    const attr = state.item.attributes
    const song = {
      name: attr.name,
      artist: attr.artistName,
      url: state.item.assetURL,
      imagePath: MusicKit.formatArtworkURL(attr.artwork, 50, 50)
    }
    console.log(song)
    elm.ports.setCurrentlyPlaying.send(song)
  })
  music.addEventListener(MusicKit.Events.playbackStateDidChange, (state) => {
    const current = MusicKit.PlaybackStates[state.state]
    if (["playing", "stopped", "loading"].includes(current)) {
      elm.ports.setPlayingState.send(current)
    }
  })

})


let recentlyAdded = (instance) => {
  instance.api.library.collection('recently-added', {types: 'artists,albums,playlists'})
}

let recentlyPlayed = (instance) => {
}

/*
 *{ id : String
  , title : String
  , artist : Strin/
  , imagePath : String
  , groupType : String
*/
let decodeItem = (item) => {
  return {
    id: item.id,
    title: item.attributes.name,
    artist: item.attributes.curatorName || item.attributes.artistName,
    imagePath: MusicKit.formatArtworkURL(item.attributes.artwork, 150, 150),
    groupType: item.type
  }
}  
let search = (instance, term) => {
  instance.api.search(term)
}


function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;
	    
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
	
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
	
    if (callNow) func.apply(context, args);
  };
};



